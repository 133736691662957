<template>
  <div class="freespins-container row py-4 w-100 mx-0">
    <b-list-group class="col-12 mx-auto pr-0" v-if="freespinsData.length">
      <b-list-group-item v-for="(freespin, idx) in freespinsData" :key="idx"
                         class="freespin-item main-bg py-4 px-4 px-lg-5 mt-4 text-white border-0">
        <b-row>
          <div class="col-4 col-sm-2 col-md-3 col-lg-2">
            <img :src="$config.storageUrl +'/'+ freespin.game.picture.replace(/^\/|\/$/g, '')"
                 alt="SegaSlot онлайн Казино"
                 class="mx-auto freespin-img">
          </div>
          <div class="col-8 col-sm-10 col-md-9 col-lg-10">
            <span>{{ freespin.game.name }}</span><br>
            <span>Вам доступно {{ freespin.count }} фриспинов</span>
            <div class="col-12 px-0">
              <button class="btn red-btn h-100 w-100 my-3" @click="launchUrl(freespin)">Играть</button>
            </div>
          </div>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <div class="row w-100 my-3" v-else>
      <span class="text-white mx-auto font-weight-bold">У вас пока нет фриспинов</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Freespins',
  computed: {
    freespinsData() {
      return this.$store.getters['getFreespins']
    },
  },
  methods: {
    launchUrl(freespin) {
      const gameName = freespin.game.name.split(' ').join('-')
      return this.$router.push({
        name: 'TheGame',
        params: {
          gameName: gameName,
          gameId: freespin.game.id,
          game: freespin.game,
          roundId: freespin.id,
          provider: freespin.system,
          isLive: freespin.game.isLive,
          restoring: false
        }
      })
    }
  }
}
</script>

<style scoped>
.freespin-img {
  width: 80px;
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
  border-radius: 7px;
}

.freespin-item {
  background-color: #001844 !important;
  border: unset !important;
  box-shadow: 0 0 6px 5px #001844 !important;
  border-radius: 7px;
}

.freespin-item:hover {
  box-shadow: 0 0 6px 5px #001844 !important;
}
</style>