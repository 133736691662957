<template>
    <b-modal
        id="freeRoundSelectorModal"
        ref="freeRoundSelectorModal"
        v-bind:title="'Вам доступно '+ (freeRoundData ? freeRoundData.count : '') + ' бесплатных вращения'"
        size="md"
        :hide-footer="true"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        modal-class="main-modal page-main-block"
        header-class="text-white border-0 d-flex justify-content-center"
        header-close-variant="orange"
        title-class="text-center underlined-muted-title"
        body-class="pt-0"
    >
      <b-card no-body class="col-12 border-0 text-white bg-transparent">
        <div class="form-group" v-if="freeRoundData && freeRoundData.game">
          <div class="col-md-12 mx-auto mb-3 text-center px-0">
            <h5 class="text-center mb-3">
              <span>{{ freeRoundData.game.name }}</span>
            </h5>
            <div class="row text-center mb-3">
              <img
                  :src="$config.storageUrl +'/'+ freeRoundData.game.picture.replace(/^\/|\/$/g, '')" alt="SegaSlot онлайн Казино"
                  class="mx-auto alive-popup-game-image"
              >
            </div>
            <div class="row w-100 mx-0 mt-2 justify-content-center">
              <div class="col-12 col-lg-12 px-0 mx-0 mx-lg-2 mx-auto ">
                <a class="btn red-btn w-100" @click="goToGameView()">Использовать</a>
              </div>
              <div class="col-6 text-center px-0 mx-auto">
                <a class="btn text-white mt-2 close-btn"  @click="hideAlert(freespin)"> Закрыть </a>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
</template>

<script>
import axios from "axios";
import Config from "@/config";

export default {
  name: "FreeRoundSelector",
  data() {
    return {
      round: false,
      result: false,
      game: null
    }
  },
  computed: {
    freeRoundData(){
      return this.$store.getters['freeRound']
    }
  },
  async mounted () {
    this.$store.dispatch('checkUserFreeRound')
    this.$root.$emit('bv::show::modal', 'freeRoundSelectorModal')
  },
  methods: {
    hideAlert() {
      if (this.freeRoundData) {
        axios.get(Config.baseUrl + '/Api/hideFreespinAlert/' + this.freeRoundData.id + '/' + this.freeRoundData.system, {crossdomain: true})
            .then(() => {
              window.location.reload()
            }).catch(err => {
          console.log(err.message)
        })
      }
    },
    goToGameView () {
      this.$root.$emit('bv::hide::modal', 'freeRoundSelectorModal')
      const gameName = this.freeRoundData.game['name'].split(' ').join('-')
      return this.$router.push({
        name: 'TheGame',
        params: {
          gameName: gameName,
          gameId: this.freeRoundData.game.id,
          game: this.freeRoundData.game,
          roundId: this.freeRoundData.id,
          provider: this.freeRoundData.system,
          isLive: this.freeRoundData.game.isLive,
          restoring: false
        }
      })
    },
  }
}
</script>

<style scoped>
.alive-popup-game-image {
  height: 180px;
  box-shadow: 0 0 7px 2px #19428d;
  padding: 15px;
  object-fit: contain;
  margin-top: 5px;
  max-width: 65%;
}
.freeround-toGame{
    border: 1px solid #0ce8ae !important;
    border-radius: 30px;
    color: #0ce8ae;
}
.freeround-toGame:hover{
  background-color: #0ce8ae!important;
}

@media screen and (max-width: 450px) {
  .alive-popup-game-image {
    max-width: 260px;
  }
}
</style>
