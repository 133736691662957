<template>
  <div id="app" class="h-100">
    <div class="main-container mx-auto px-0 h-100">
      <the-header></the-header>
      <router-view></router-view>
      <main-features v-if="!isGamePage"></main-features>
      <div class="page-up d-none d-md-flex position-fixed cursor-pointer main-bg-light" @click="scrollTop">
        <span class="d-block m-auto position-relative"><i class="fa fa-scroll-up"></i></span>
      </div>
      <the-footer></the-footer>
    </div>
    <FlashMessages></FlashMessages>
    <messages></messages>
    <AliveGamePopup></AliveGamePopup>
    <login-modal></login-modal>
    <registration-modal></registration-modal>
    <recover-password></recover-password>
    <old-version-pop-up v-if="showOldVersionPopUp"></old-version-pop-up>
    <AfterWithdrawModal></AfterWithdrawModal>
    <FreeRoundSelector v-if="!userHasAliveGame && allowFreeRoundPopup"></FreeRoundSelector>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import Messages from '@/components/Messages'
import FlashMessages from '@/components/FlashMessages'
import AliveGamePopup from "./components/games/AliveGamePopup";
import MainFeatures from '@/components/home/MainFeatures'
import LoginModal from '@/components/user/Login'
import RegistrationModal from '@/components/user/Registration'
import RecoverPassword from '@/components/user/RecoverPassword'
import OldVersionPopUp from "@/components/oldVersionPopUp"
import AfterWithdrawModal from "@/components/payment/AfterWithdrawModal"
import FreeRoundSelector from "@/components/games/FreeRoundSelector";

export default {
  name: 'app',
  data() {
    return {
      currentPath: '',
      showOldVersionPopUp: false,
      allowFreeRoundPopup: false
    }
  },
  components: {
    FreeRoundSelector,
    OldVersionPopUp,
    TheHeader,
    TheFooter,
    FlashMessages,
    Messages,
    AliveGamePopup,
    MainFeatures,
    LoginModal,
    RegistrationModal,
    RecoverPassword,
    AfterWithdrawModal
  },
  computed: {
    isGamePage() {
      return this.$route.name === 'TheGame'
    },
    userHasAliveGame() {
      const userData = this.$store.getters.userData
      if (!userData) {
        return false
      }
      const aliveGames = this.$store.getters.aliveGames
      return !!(aliveGames && aliveGames.length)
    },
    freeRound() {
      return this.$store.getters.freeRound
    },
  },
  methods: {
    scrollTop() {
      document.body.scrollTop = 0;
      document.body.onscroll
    },
    isMobile() {
      return this.$_config.environment.mobile
    }
  },
  watch: {
    $route() {
      this.currentPath = this.$route.name;

      let chatBlock = document.getElementById("tawkchat-container");
      if (chatBlock) {
        if (this.isGamePage && this.isMobile()) {
          chatBlock.style.display = "none"
        } else {
          chatBlock.style.display = "block"
        }
      }
    },
    alive: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.allowAliveGamePopup = true
        }
      }
    },
    freeRound: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.allowFreeRoundPopup = true
        }
      }
    }
  },
  mounted(){
    if(!this.isGamePage){
      this.$store.dispatch('checkUserFreeRound')
    }
  }
}
</script>

